<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card">
      <div class="card-header justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <div class="flex flex-row mb-2 float-right">
          <el-button v-if="currentRole.can_create" type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/> Create new Admin</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="30%">Name</th>
              <th width="30%">Email</th>
              <th width="10%">Role</th>
              <th>Created Date</th>
              <th>Last Login Date</th>
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="(item) in userList" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.role.name }}</td>
              <td>{{ item.created_at_str }}</td>
              <td>{{ item.last_login_at_str }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 150px">
                  <a @click="doShowModalEdit(item)" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon icon="pen" class="text-primary"/>&nbsp; Edit Admin</a>
                  <a v-if="item.id !== $store.getters.currentMyProfile.id" @click="confirmDeleteAdmin(item)" class="dropdown-item btn-sm cursor-pointer"><font-awesome-icon icon="trash" class="text-danger"/>&nbsp; Delete Admin</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal v-model="showModalCreate" :title="modalTitle" hide-footer>
          <b-form @submit.prevent="submitAdmin" @reset="closeModalCreate">
            <b-form-group label="Admin Name">
              <b-input
                v-model="v$.form.name.$model"
                placeholder="Input admin name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
                <span> {{ v$.form.name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Admin Email">
              <b-input
                v-model="v$.form.email.$model"
                placeholder="Input admin email"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.email.$invalid" v-if="v$.form.email.$errors[0]">
                <span> {{ v$.form.email.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Admin Password">
              <b-input-group>
                <template #append>
                  <b-input-group-text class="cursor-pointer" @click="handlerClickGeneratePassword">
                    <span><font-awesome-icon icon="cogs"/></span>
                  </b-input-group-text>
                </template>
                <b-input
                  :disabled="modalType == 'add'"
                  v-model="v$.form.password.$model"
                  placeholder="Input admin password."
                  type="text"/>
              </b-input-group>
              <small v-if="modalType === 'edit'" class="text-brand">
                <i>(Leave blank if you won't update admin password)</i>
              </small>
              <b-form-invalid-feedback :state="!v$.form.password.$invalid" v-if="v$.form.password.$errors[0]">
                <span> {{ v$.form.password.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Role">
              <el-select @change="onChangeRole" v-model="v$.form.role_id.$model" class="w-full" placeholder="Select admin role">
                <el-option v-for="(item) in roleList" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.role_id.$invalid" v-if="v$.form.role_id.$errors[0]">
                <span> {{ v$.form.role_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group v-if="[finance_role_id, crm_role_id].includes(form.role_id)" label="Is Maker">
              <el-switch
                :active-value="true"
                :inactive-value="false"
                active-text="Yes"
                inactive-text="No"
                v-model="form.is_maker">
              </el-switch>
            </b-form-group>
            <b-form-group v-if="[finance_role_id, crm_role_id].includes(form.role_id)" label="Is Checker">
              <el-switch
                :active-value="true"
                :inactive-value="false"
                active-text="Yes"
                inactive-text="No"
                v-model="form.is_checker">
              </el-switch>
            </b-form-group>
            <div class="float-right" v-if="currentRole['can_' + (modalType == 'add' ? 'create' : modalType)]">
              <el-button @click="submitAdmin" :loading="loading.form_admin" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
                <el-button @click="closeModalCreate" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import crypto from 'crypto';
import {
  required, email, requiredIf, helpers,
} from '@vuelidate/validators';

import {
  GET_INTERNALUSERS, CREATE_INTERNALUSERS, UPDATE_INTERNALUSERS, DELETE_INTERNALUSERS,
} from '@/store/modules/internal_users';
import { GET_ALL_ROLES } from '@/store/modules/roles';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import base64RSA from '@/library/rsa-encrypt';
import passwordComplexity from '@/library/password-complexity';

export default {
  name: 'Admin',
  metaInfo: {
    title: 'Admin',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      showModalCreate: false,
      loading: {
        form_admin: false,
        table: false,
      },
      modalType: 'add',
      user: {
        search_by: 'name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
        ],
      },
      per_page: 10,
      total_rows: 0,
      page: 1,
      userList: [],
      roleList: [],
      form: {
        name: '',
        email: '',
        password: '',
        role_id: '',
        is_maker: false,
        is_checker: false,
      },
      selectedRole: {},
      finance_role_id: process.env.VUE_APP_FINANCE_ROLE_ID,
      crm_role_id: process.env.VUE_APP_CRM_ROLE_ID,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        password: {
          required: requiredIf(this.modalType === 'add'),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        role_id: { required },
        is_maker: { required: requiredIf(this.form.role_id === this.finance_role_id) },
        is_checker: { required: requiredIf(this.form.role_id === this.finance_role_id) },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.userList.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    modalTitle() {
      return this.modalType === 'add' ? 'Create New Admin' : 'Edit Admin';
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    await this.getRoleList();
    loader.hide();
  },
  methods: {
    generateRandomPassword() {
      const wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';
      const length = 10;
      return Array.from(crypto.randomFillSync(new Uint8Array(length))).map((x) => wishlist[x % wishlist.length]).join('');
    },
    async getList() {
      await this.$store.dispatch(GET_INTERNALUSERS, {
        page: this.page,
      }).then((response) => {
        this.userList = response.data.rows;
        this.total_rows = response.data.count;
      }).catch(() => {});
    },
    async getRoleList() {
      await this.$store.dispatch(GET_ALL_ROLES).then((response) => {
        this.roleList = response.rows;
      }).catch(() => {});
    },
    onChangeRole(value) {
      this.selectedRole = this.roleList.find((v) => v.id === value);
    },
    async pageChangeHandler(page) {
      this.page = page;
      this.loading.table = true;
      await this.getList();
      this.loading.table = false;
    },
    doShowModalCreate() {
      this.showModalCreate = true;
      this.modalType = 'add';
      this.form = {
        name: '',
        email: '',
        password: this.generateRandomPassword(),
        role_id: '',
        is_maker: false,
        is_checker: false,
      };
      this.v$.form.$touch();
    },
    doShowModalEdit(item) {
      this.showModalCreate = true;
      this.modalType = 'edit';
      /* eslint-disable prefer-object-spread */
      this.form = Object.assign({ password: '' }, {
        name: item.name,
        email: item.email,
        role_id: item.role.id,
        id: item.id,
        is_maker: item.is_maker,
        is_checker: item.is_checker,
      });
      this.v$.form.$touch();
    },
    closeModalCreate() {
      this.showModalCreate = false;
      this.form = {
        name: '',
        email: '',
        password: '',
        role_id: '',
        is_maker: false,
        is_checker: false,
      };
    },
    submitAdmin() {
      if (this.modalType === 'add') {
        this.createAdmin();
      } else {
        this.updateAdmin();
      }
    },
    createAdmin() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      this.$confirm(`You will create new admin with role as a ${this.selectedRole.name}. Continue?`, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            /* eslint-disable prefer-object-spread */
            const obj = Object.assign({}, this.form);
            obj.password = base64RSA.encrypt(obj.password).toString('base64');
            this.loading.form_admin = true;
            await this.$store.dispatch(CREATE_INTERNALUSERS, obj).then(() => {
              this.showModalCreate = false;
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'New admin created',
              });
              this.getList();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            this.loading.form_admin = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    updateAdmin() {
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        if (this.v$.form.$errors.length === 1 && !this.form.password && this.v$.form.$errors[0].$validator === 'complexity') {
          // skip
        } else {
          return;
        }
      }

      this.$confirm('You will update this admin data. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            /* eslint-disable prefer-object-spread */
            const obj = Object.assign({}, this.form);
            this.loading.form_admin = true;
            await this.$store.dispatch(UPDATE_INTERNALUSERS, {
              id: obj.id,
              data: {
                email: obj.email,
                password: obj.password ? base64RSA.encrypt(obj.password).toString('base64') : null,
                role_id: obj.role_id,
                name: obj.name,
                is_maker: obj.is_maker,
                is_checker: obj.is_checker,
              },
            }).then(() => {
              this.showModalCreate = false;
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Admin data updated',
              });
              this.getList();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            this.loading.form_admin = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    confirmDeleteAdmin(item) {
      this.$confirm(`You will remove ${item.name} from Admin user. Continue?`, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(DELETE_INTERNALUSERS, {
              id: item.id,
            }).then(() => {
              this.showModalCreate = false;
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Admin deleted',
              });
              this.getList();
              done();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    handlerClickGeneratePassword() {
      try {
        this.form.password = this.generateRandomPassword();
        this.v$.form.$touch();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
